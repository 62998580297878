import styled from "styled-components";

export const HistoryDeviceMetricsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: stretch;
    background: #FFFFFF;

    & .ant-btn-disabled {
        background-color: #999999 !important;
        color: #EEEEEE;
    }
`;

export const FilterBar = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 10px 0px 20px 0px;

    & .query-button .ant-btn-disabled {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button .ant-btn-disabled:hover {
        background-color: #999999 !important;
        color: #EEEEEE;
    }

    & .query-button {
        background-color: #1CB273;
    }

    & .query-button:hover {
        background-color: #1EA77B !important;
    }
`;

export const FilterItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-select-selector {
        overflow: hidden;
    }

    & .ant-select-selection-overflow {
        flex-wrap: nowrap;
    }
`;

export const FilterItemLabel = styled.div`
    font-size: 12px;
    color: #333333;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
    letter-spacing: 2px;
`;

export const SearchResult = styled.div`
    border-radius: 5px;
    flex: 1 1 auto;
`;

export const ChartsWrapper = styled.div`
    flex: 1 1 auto;
    display: grid;
    overflow-x: hidden;
    overflow-y: auto;
    grid-gap: 30px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(300px, 1fr);

    padding-right: 20px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const ChartHeader = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 48px;
`;

export const ChartName = styled.div`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-right: 10px;
`;

export const ChartHeaderColorBlock = styled.div`
    width: 3px;
    height: 100%;
`;

export const ChartInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;

export const ChartLatestMetricTime = styled.div`
    font-size: 11px;
    color: #555555;
`;

export const ChartUnit = styled.div`
    font-size: 11px;
    color: #555555;
`;

export const EChartsWrapper = styled.div`
    flex: 1 1 auto;
`;



