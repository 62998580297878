import { useEffect, useState } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { DeviceDetailsWrapper, InfoItem, InfoLabel, InfoValue } from "./DeviceDetails.styled";
import DeviceStatus from "../../../../../components/ui-components/device-status/DeviceStatus";
import DeviceApi from "../../../../../api/DeviceApi";
import DeviceRssi from "../../../../../components/ui-components/rssi/DeviceRssi";
import BracketBorderCard from "../../common/bracket-border-card/BracketBorderCard";

interface DeviceDetailsProps {
    deviceId: string
}

let DeviceDetails: React.FC<DeviceDetailsProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();

    // state
    let [deviceView, setDeviceView] = useState(null);
    
    // functions
    let fetchDeviceViewData = async (deviceId)=> {
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        setDeviceView(deviceView);
    }

    let formatLastOnlineTime = (timestamp: number | string)=> {
        timestamp = Number(timestamp);
        let timestampNow = new Date().getTime();
        let value = timestampNow - timestamp;

        if(value==null || value <0) {
            return 'N/A'
        } else if(value < 60 * 1000) {
            return `${(value/1000).toFixed(0)} 秒`;
        } else if(value < 60 * 60 * 1000) {
            return `${(value/(60 * 1000)).toFixed(0)} 分`;
        } else if(value < 24 * 60 * 60 * 1000) {
            return `${(value/(60 * 60 * 1000)).toFixed(0)} 小时`;
        } else {
            return `${(value/(24 * 60 * 60 * 1000)).toFixed(0)} 天`;
        }
        return 'N/A';
    }

    // effect
    useEffect(()=> {
        fetchDeviceViewData(props.deviceId);
        let refreshTask = setInterval(async ()=> {
            await fetchDeviceViewData(props.deviceId);
        }, 5000);

        return ()=> {
            clearInterval(refreshTask);
        }
    }, []);

    useEffect(()=> {
        let originalBackground = document.body.style.backgroundColor;
        document.body.style.backgroundColor = '#2A2A2A';
        return ()=> {
            document.body.style.backgroundColor = originalBackground;
        };
    }, []);

    // render
    return (
        deviceView ? <DeviceDetailsWrapper>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>设备名称</InfoLabel>
                    <InfoValue>{deviceView.device.name}</InfoValue>
                </InfoItem>
            </BracketBorderCard>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>设备状态</InfoLabel>
                    <InfoValue><DeviceStatus status={deviceView.node.status} /></InfoValue>
                </InfoItem>
            </BracketBorderCard>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>告警状态</InfoLabel>
                    <InfoValue>正常<CheckCircleFilled style={{color: '#00AF50', fontSize: '18px', margin: '5px'}} /> </InfoValue>
                </InfoItem>
            </BracketBorderCard>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>信号强度</InfoLabel>
                    <InfoValue><DeviceRssi deviceRssi={deviceView.latestHeartBeatMetric.rssi} /> </InfoValue>
                </InfoItem>
            </BracketBorderCard>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>定位方式</InfoLabel>
                    <InfoValue>{deviceView.latestHeartBeatMetric?.altitude == null ? '基站定位': 'GNSS'}</InfoValue>
                </InfoItem>
            </BracketBorderCard>
            <BracketBorderCard>
                <InfoItem>
                    <InfoLabel>最后在线时间</InfoLabel>
                    <InfoValue>{deviceView.node.status=='ONLINE' ? 'N/A' : formatLastOnlineTime(deviceView.latestHeartBeatMetric.timestamp) + '前'}</InfoValue>
                </InfoItem>
            </BracketBorderCard>
        </DeviceDetailsWrapper>
        : <DeviceDetailsWrapper></DeviceDetailsWrapper>
    )
};

export default DeviceDetails;