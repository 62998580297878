import { useEffect, useRef, useState } from "react";
import { Progress, ProgressProps } from "antd";
import CountUp from "react-countup";
import * as echarts from "echarts";
import { ECharts } from "echarts";
import 'echarts-liquidfill';
import EventManager from "../../common/event-manager/EventManager";
import DeviceApi from "../../../../../api/DeviceApi";
import { Content, DeviceIndex, DeviceName, DeviceNameAndTrafficUsage, DeviceNetworkTrafficWrapper, DeviceTrafficStatisticsItem, DeviceTrafficStatisticsList, EChartHighlighData, EChartTitle, EChartWrapper, StatisticDataName, StatisticDataValue, TrafficPoolUsageChartWrapper, TrafficUsageText } from "./DeviceNetworkTraffix.styled";
import AutoScrollUtils from "../../../../../util/AutoScrollUtils";
import SimCardTrafficApi from "../../../../../api/SimCardTrafficApi";

interface DeviceNetworkTrafficProps {
    eventManager: EventManager
}

let DeviceNetworkTraffic: React.FC<DeviceNetworkTrafficProps> = (props)=> {
    // api
    let deviceApi = new DeviceApi();
    let simCardTrafficApi = new SimCardTrafficApi();

    // ref
    let carouselRef = useRef<any>();
    let echartsDomRef = useRef<HTMLElement>();
    let echartsRef = useRef<ECharts>(null);

    // state
    let [deviceViews, setDeviceViews] = useState([]);
    let [latestSimCardTrafficPool, setLatestSimCardTrafficPool] = useState({used: 0, total: 100, free: 100} as any);
    let [highlightIndex, setHighlightIndex] = useState(0);
    let [countUpStart, setCountUpStart] = useState(0);
    let [countUpEnd, setCountUpEnd] = useState(0);
    
    // event
    let initializeCarousel = (dom)=> {
        if (dom) {
            carouselRef.current = AutoScrollUtils.addAutoScroll(dom);
        } else {
            if (carouselRef.current) {
                AutoScrollUtils.removeAutoScroll(carouselRef.current);
            }
        }
    };

    // init ECharts
    let initECharts = (dom: HTMLElement)=> {
        let echartsInstance = echarts.init(dom);
        echartsRef.current = echartsInstance;

        // init options
        let option = {
            tooltip: {
                show: false,
                trigger: 'item'
            },
            legend: {
                show: false,
            },
            grid: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 0,
            },
            series: [
                {
                    // name: 'OUTER CIRCLE',
                    name: '',
                    type: 'liquidFill',
                    color: ['#294D99', '#156ACF', '#1598ED', '#45BDFF'],
                    itemStyle: {
                        borderWidth: 2,
                    },
                    outline: {
                        show: true,
                        borderDistance: 5,
                        itemStyle: {
                            color: 'none',
                            borderColor: '#45BDFF',
                            borderWidth: 5,
                            shadowBlur: 20,
                            shadowColor: 'rgba(69, 189, 255, 0.25)'
                        }
                    },
                    label: {
                        show: false,
                    },
                    backgroundStyle: {
                        borderWidth: 5,
                        color: 'rgba(0, 0, 0, 0.2)'
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 18,
                        }
                    },
                    data: [],
                },
            ]
        };
        echartsInstance.setOption(option);
    }

    let loadDeviceViews = async ()=> {
        let deviceViews = (await deviceApi.getAllDeviceViews()).data.data;
        deviceViews = deviceViews.map((deviceView)=> {
            deviceView.node.trafficTotal = deviceView.latestSimCardTraffic?.total ?? 100;
            deviceView.node.trafficUsed = deviceView.latestSimCardTraffic?.used ?? 0.00;
            if (deviceViews.indexOf(deviceView)==0) {
                deviceView.node.trafficUsed = 0.00;
            }
            return deviceView;
        }).sort((a, b)=> {
            let aTrafficFree = a.node.trafficTotal - a.node.trafficUsed;
            let bTrafficFree = b.node.trafficTotal - b.node.trafficUsed;
            return aTrafficFree - bTrafficFree;
        });
        setDeviceViews(deviceViews);
    };

    let loadLatestSimCardTrafficPool = async ()=> {
        let latestSimCardTrafficPools = (await simCardTrafficApi.getLatestSimCardTrafficPools()).data.data;
        if (latestSimCardTrafficPools.length <= 0) {
            return;
        }
        let latestSimCardTrafficPool = latestSimCardTrafficPools[0];
        latestSimCardTrafficPool.free = latestSimCardTrafficPool.total - latestSimCardTrafficPool.used;
        setLatestSimCardTrafficPool(latestSimCardTrafficPool);

        // refresh echarts options
        let echartsInstance = echartsRef.current;
        if (echartsInstance == null) {
            return;
        }
        echartsInstance.setOption({
            series: [
                {
                    data: [
                        latestSimCardTrafficPool.used / latestSimCardTrafficPool.total,
                        latestSimCardTrafficPool.used / latestSimCardTrafficPool.total / 2, 
                        latestSimCardTrafficPool.used / latestSimCardTrafficPool.total / 3,
                    ],
                }
            ]
        });
        echartsRef.current?.resize();
    };

    // onload
    useEffect(()=> {
        loadDeviceViews();

        let refreshTask = setInterval(()=> {
            loadDeviceViews();
            loadLatestSimCardTrafficPool();
        }, 120000);
        loadDeviceViews();
        loadLatestSimCardTrafficPool();
        
        let highlightIndex = 1;
        let highlightAnimation = ()=> {
            if (echartsRef.current) {
                let echarts = echartsRef.current;
                echarts.dispatchAction({
                    type: 'downplay',
                    seriesIndex: 0,
                    dataIndex: highlightIndex,
                });
                highlightIndex%=2;
                echarts.dispatchAction({
                    type: 'highlight',
                    seriesIndex: 0,
                    dataIndex: highlightIndex,
                });
                setHighlightIndex(highlightIndex);
            }
        }
        let highlighTask = setInterval(()=> {
            highlightAnimation();
        }, 3000);

        if (echartsDomRef.current!=null) {
            initECharts(echartsDomRef.current);
        }

        return ()=> {
            clearInterval(refreshTask);
            clearInterval(highlighTask);
            if (echartsRef.current) {
                echartsRef.current.dispose();
            }
        }
    }, []);

    useEffect(()=> {
        let value = highlightIndex == 0 ? latestSimCardTrafficPool.free : latestSimCardTrafficPool.used;
        setCountUpStart(countUpEnd);
        setCountUpEnd(value);
    }, [highlightIndex]);

    // render    
    const conicColors: ProgressProps['strokeColor'] = {
        '0%': 'rgba(22, 120, 255, 0.5)',
        '20%': 'rgba(22, 120, 255, 0.8)',
        '100%': 'rgba(255, 255, 255, 0.95)',
    };
    
    let getHighlightLabel = (highlightIndex: number)=> {
        switch(highlightIndex) {
            case 0:
                return '未使用';
            case 1:
                return '已使用';
        }
        return '';
    };

    return (
        <DeviceNetworkTrafficWrapper>
            <Content>
                <TrafficPoolUsageChartWrapper>
                    <EChartHighlighData>
                        <StatisticDataValue>
                            <span style={{fontSize: '24px'}}>{Math.round(latestSimCardTrafficPool.used)}</span>
                            <span style={{fontSize: '16px', marginLeft: '3px'}}>MB</span>
                        </StatisticDataValue>
                        <StatisticDataName>已使用</StatisticDataName>
                    </EChartHighlighData>

                    <EChartTitle>流量池使用 {(latestSimCardTrafficPool.used / latestSimCardTrafficPool.total * 100).toFixed(0)}%</EChartTitle>
                    <EChartWrapper ref={(dom)=> {echartsDomRef.current=dom}}>
                    </EChartWrapper>
                </TrafficPoolUsageChartWrapper>

                <DeviceTrafficStatisticsList ref={(dom)=> initializeCarousel(dom)}>
                    {
                        deviceViews.map((e, index)=> {
                            return <DeviceTrafficStatisticsItem key={e.device.id} onClick={()=> props.eventManager.notifyDeviceSelected(e.device.id)}>
                                <DeviceIndex>NO.{index+1}</DeviceIndex>
                                <DeviceNameAndTrafficUsage>
                                    <DeviceName>{e.device.name}</DeviceName>
                                    <Progress
                                        size="small"
                                        status="active"
                                        showInfo={true}
                                        percentPosition={{ align: 'end', type: 'outer' }}
                                        strokeColor={conicColors}
                                        percent={Math.round(e.node.trafficUsed / e.node.trafficTotal * 100.0)} 
                                        format={(percent, successPercent)=> {
                                            return <TrafficUsageText>{`${e.node.trafficUsed.toFixed(2)} / ${e.node.trafficTotal} MB`}</TrafficUsageText>;
                                        }}
                                    />
                                </DeviceNameAndTrafficUsage>
                            </DeviceTrafficStatisticsItem>
                        })
                    }
                </DeviceTrafficStatisticsList>
            </Content>
        </DeviceNetworkTrafficWrapper>
    )
};

export default DeviceNetworkTraffic;

