import { ECharts } from "echarts";
import * as echarts from "echarts";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { ChartHeader, ChartUnit, ChartName, ChartWrapper, DeviceMetricsWrapper, EChartsWrapper, ChartInfo, ChartCurrentValue, ChartLatestMetricTime } from "./DeviceMetrics.styled";
import DeviceMetricApi from "../../../../../api/DeviceMetricApi";
import SeriesColorUtils from "../../../../../util/SeriesColorUtils";
import StringUtils from "../../../../../util/StringUtils";
import DeviceApi from "../../../../../api/DeviceApi";

interface DeviceMetricProps {
    deviceId: string
}

const DeviceMetrics: React.FC<DeviceMetricProps> = (props)=> {
    let { deviceId } = props;

    // api
    let deviceApi = new DeviceApi();
    let deviceMetricApi = new DeviceMetricApi();

    // ref
    let echartsDomsRef = useRef<HTMLElement[]>([]);
    let echartsRef =useRef<ECharts[]>([]);
    let deviceMetricsSeriesListRef = useRef([]);
    let deviceViewRef = useRef<any>(null);

    // state
    let [deviceMetricProperties, setDeviceMetricProperties] = useState([]);
    let [repaint, setRepaint] = useState(null);

    // functions
    let fetchRealtimeMetricsSeries = async ()=> {
        // fetch data
        let deviceMetricsSeriesList = (await deviceMetricApi.getRealtimeDeviceMetricsSeries(deviceId)).data.data;
        return deviceMetricsSeriesList;
    };

    let fetchDeviceViewData = async (deviceId)=> {
        let deviceView = (await deviceApi.getDeviceViewByDeviceId(deviceId)).data.data;
        deviceViewRef.current = deviceView;
    };

    let updateSeriesData = async (deviceMetricsSeriesList: any[])=> {
        // process
        for(let i=0; i<deviceMetricsSeriesList.length; i++) {
            let deviceMetricsSeries = deviceMetricsSeriesList[i];
            
            // series data
            let seriesData = [];
            let metrics = deviceMetricsSeries.metrics;
            let lastMetricTimestamp: number = null;
            let paddingGapMillis = 5 * 10000;
            for (let i = 0; i < metrics.length; i++) {
                let metric = metrics[i];
                
                // pad data point
                if (lastMetricTimestamp !=null && Math.abs(metric.timestamp - lastMetricTimestamp) > paddingGapMillis) {
                    // down data point
                    let downDataPoint = {name: '' + (Number(lastMetricTimestamp)+1), value: [(Number(lastMetricTimestamp)+1), 0]};
                    seriesData.push(downDataPoint);

                    // up data point
                    let upDataPoint: any = {name: '' + (Number(metric.timestamp)-1), value: [(Number(metric.timestamp)-1), 0]};
                    seriesData.push(upDataPoint);
                }
                lastMetricTimestamp = metric.timestamp;

                // add to series data
                seriesData.push({
                    name: metric.timestamp + '',
                    value: [Number(metric.timestamp), Number(metric.value)],
                });
            }

            // echarts options
            if (echartsRef.current[i] != null) {
                echartsRef.current[i].setOption({
                    series: [{
                        data: seriesData
                    }]
                });
            }
        }
    };

    let formatTimestampAxis = (value)=> {
        let text = moment(value).format('HH:mm:ss');
        return text;
    }

    // initialize ECharts wrapper dom
    let initializeWrapperDom = (dom)=> {
        if (dom == null) {
            return;
        }
        let echartsWrapperDom = dom;

        // refresh grid layout
        if (echartsWrapperDom && deviceMetricProperties.length > 0) {
            if (deviceMetricProperties.length <= 1) {
                echartsWrapperDom.style.gridTemplateColumns = '1fr';
                echartsWrapperDom.style.gridTemplateRows = '1fr';
            } else if (deviceMetricProperties.length <= 2) {
                echartsWrapperDom.style.gridTemplateColumns = 'repeat(2, minmax(0, 1fr))';
                echartsWrapperDom.style.gridTemplateRows = '1fr';
            } else if (deviceMetricProperties.length <= 4) {
                echartsWrapperDom.style.gridTemplateColumns = 'repeat(2, minmax(0, 1fr))';
                echartsWrapperDom.style.gridTemplateRows = 'repeat(2, 1fr)';
            } else if (deviceMetricProperties.length <= 6) {
                echartsWrapperDom.style.gridTemplateColumns = 'repeat(3, minmax(0, 1fr))';
                echartsWrapperDom.style.gridTemplateRows = 'repeat(2, 1fr)';
            } else {
                echartsWrapperDom.style.gridTemplateColumns = 'repeat(3, minmax(0, 1fr))';
                echartsWrapperDom.style.gridTemplateRows = '';
                echartsWrapperDom.style.gridAutoRows = 'minmax(220px, 1fr)';
            }
            resizeEcharts();
        }
    }

    
    // init ECharts
    let initECharts = (dom: HTMLElement, index: number)=> {
        if (dom == null) {
            // element unmount event
            // let echarts = echartsRef.current[index];
            // echarts.dispose();
            return;
        }
        if (echarts.getInstanceByDom(dom)) {
            return;
        }

        // save to reference
        echartsDomsRef.current.push(dom);
        let seriesIndex = echartsDomsRef.current.indexOf(dom);

        // init echarts
        let chart = echarts.init(dom);
        echartsRef.current.push(chart);

        // series
        let property = deviceMetricProperties[seriesIndex];

        // init options
        let option = {
            backgroundColor: 'transparent',
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                },
                backgroundColor: "#EFEFEF",
                confine: true,
                formatter: function(params) {
                    if (params == null) {
                        return '';
                    }

                    let seriesContent = '';
                    for(let param of params) {
                        if (param.value==null) {
                            continue;
                        }
                        
                        seriesContent += `
                            <div style="">
                                <div style="padding: 5px 0px;">
                                    <span style="display: inline-block; width: 50px;">测量值:</span> 
                                    <span style="display: inline-block">${param.value[1]} ${property.unit}</span>
                                </div>
                                <div>
                                    <span style="display: inline-block; width: 50px;">时间:</span> 
                                    <span style="display: inline-block">${moment(param.value[0]).format('YYYY-MM-DD HH:mm:ss')}</span>
                                </div>
                            </div>
                        `;
                        break;
                    }
                    let content=`
                        <div style="width: 230px;">
                            ${seriesContent}
                        </div>
                    `;
                    return content;
                },
            },
            grid: {
                x: 40,
                y: 10,
                x2: 40,
                y2: 20,
            },
            xAxis: {
                type: 'time',
                margin: 4,
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    },
                },
                splitNumber: 2,
                minorTick: {
                    show: false,
                },
                axisLabel: {
                    fontSize: 12,
                    color: '#FFFFFF',
                    formatter: function (value, index) {
                        return formatTimestampAxis(value);
                    }
                },
                axisPointer: {
                    type: 'line',
                    animation: true,
                    label: {
                        show: true,
                        width: 165,
                    }
                },
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#CCCCCC',
                        width: 1,
                        type: 'dashed',
                    }
                },
                nameTextStyle: {
                    align: 'left',
                },
                axisLabel: {
                    fontSize: 12,
                    color: '#FFFFFF',
                },
                min: property.min != null ? parseFloat(property.min) : 'dataMin',
                max: property.max != null ? parseFloat(property.max) : 'dataMax',
            },
            dataZoom: [
                {
                  type: 'inside',
                  start: 90,
                  end: 100
                }
            ],
            series: [
                {
                    name: property.name,
                    type: 'line',
                    color: SeriesColorUtils.getSeriesColor(seriesIndex),
                    smooth: false,
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.4,
                    },
                    data: [],
                    shadowBlur: 20,
                }
            ],
        };

        chart.setOption(option);
        chart.group='DeviceRealtimeMetrics';
        echarts.connect('DeviceRealtimeMetrics');

        if (deviceMetricProperties.length == seriesIndex + 1) {
            // fetch data
            setTimeout(()=> {
                updateSeriesData(deviceMetricsSeriesListRef.current);
            });
        }
    }

    let resizeEcharts = ()=> {
        for(let chart of echartsRef.current) {
            chart.resize();
        }
    };

    // effect
    useEffect(()=> {
        let refreshTaskId: any = null;
        let disposed = false;

        let init = async ()=> {
            // fetch data
            let deviceMetricsSeriesList = await fetchRealtimeMetricsSeries();
            deviceMetricsSeriesListRef.current = deviceMetricsSeriesList;

            // device template properties
            if (deviceMetricProperties.length <=0 ) {
                deviceMetricProperties = [];
                for(let deviceMetricsSeries of deviceMetricsSeriesList) {
                    deviceMetricProperties.push(deviceMetricsSeries.property);
                }

            } else {
                updateSeriesData(deviceMetricsSeriesList);
            }

            // refresh task
            refreshTaskId = window.setInterval(async ()=> {
                deviceMetricsSeriesList = await fetchRealtimeMetricsSeries();
                deviceMetricsSeriesListRef.current = deviceMetricsSeriesList;
                updateSeriesData(deviceMetricsSeriesList);
                setDeviceMetricProperties([].concat(deviceMetricProperties))

                // device view
                fetchDeviceViewData(props.deviceId);

                if (disposed) {
                    window.clearInterval(refreshTaskId);
                }
            }, 7500);
        };

        init();

        // resize charts on window resize
        window.addEventListener('resize', resizeEcharts);

        // unload
        return ()=> {
            disposed = true;
            for(let chart of echartsRef.current) {
                chart.dispose();
            }

            // clear interval
            if (refreshTaskId!=null) {
                window.clearInterval(refreshTaskId);
            }

            // clear resize listener
            window.removeEventListener('resize', resizeEcharts);
        }
    }, []);

    // render
    let getLatestMetricTime = (index: number)=> {
        let deviceMetricsSeries = deviceMetricsSeriesListRef.current[index];
        if (deviceMetricsSeries != 0) {
            let metrics = deviceMetricsSeries.metrics;
            if (metrics.length > 0) {
                let latestMetrics = metrics[metrics.length-1];
                let time = moment(Number(latestMetrics.timestamp)).format('YYYY/MM/DD HH:mm:ss');
                return time;
            }
        }
        return '';
    };

    let getLatestMetricValue = (index: number)=> {
        if (deviceViewRef.current?.node.status != 'ONLINE') {
            return '/';
        }
        let deviceMetricsSeries = deviceMetricsSeriesListRef.current[index];
        if (deviceMetricsSeries != 0) {
            let metrics = deviceMetricsSeries.metrics;
            if (metrics.length > 0) {
                let latestMetrics = metrics[metrics.length-1];
                let text = latestMetrics.value;
                return text;
            }
        }
        return '';
    };

    return (
        <DeviceMetricsWrapper ref={(dom)=> initializeWrapperDom(dom)}>
            {
                deviceMetricProperties.map((e, index)=> {
                    return (
                        <ChartWrapper key={e.name}>
                            <ChartHeader>
                                <ChartName>{e.name}</ChartName>
                                <ChartCurrentValue>{getLatestMetricValue(index)}</ChartCurrentValue>
                                <ChartInfo>
                                    <ChartUnit>单位：{StringUtils.emptyToSlash(e.unit)}</ChartUnit>
                                </ChartInfo>
                            </ChartHeader>
                            <EChartsWrapper ref={(dom)=> initECharts(dom, index)}>
                            </EChartsWrapper>
                        </ChartWrapper>
                    )
                })
            }
        </DeviceMetricsWrapper>
    )
}

export default DeviceMetrics;
