import styled from "styled-components";

let titleBarHeight = 60;

export const OverviewBigScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    color: #FFFFFF;
    letter-spacing: 2px;
    background-color: rgb(5, 12, 38);
`;

export const OverviewBigScreenInnerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 850px;
    color: #FFFFFF;
    letter-spacing: 2px;
`;

export const ScreenBorderTop = styled.img`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    z-index: 100;
`;

export const ScreenBorderBottom = styled.img`
    position: absolute;
    left: -10%;
    bottom: 0px;
    width: 120%;
    height: 70px;
    z-index: 100;
`;

export const ScreenTitleBar = styled.div`
    position: absolute;
    z-index: 110;
    left: 0%;
    top: 0%;
    width: 100%;
    height: ${titleBarHeight-10}px;
    padding: 5px 20px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
`;

export const OverviewBigScreenContent = styled.div`
    display: grid;
    position: absolute;
    top: calc(${titleBarHeight}px);
    width: 100%;
    height: calc(100% - ${titleBarHeight}px);
    padding: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    grid-template-columns: 2fr 3fr 2fr;
    grid-template-rows: 30% 34% 34%;
    grid-gap: 10px;
    align-items: stretch;
    justify-items: stretch;
    box-sizing: border-box;
    z-index: 100;

    &::-webkit-scrollbar {
        display: none;
        width: 0px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &:before {
        pointer-events: none;
        content: '';
        position: absolute;
        z-index: 90;
        left: 0;
        top: 20px;
        width: 100%;
        height: 100%;
        box-shadow: inset 0px 50px 50px rgb(5, 12, 38),  inset 500px 0px 350px rgb(5, 12, 38), inset -500px -350px 350px rgb(5, 12, 38);
    }
`;

export const IntroductionVideoWrapper = styled.div`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    box-sizing: border-box;
    z-index: 100;
`;

export const DeviceMapWrapper = styled.div`
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    box-sizing: border-box;
    position: relative;
    z-index: 80;
`;

export const DeviceOnlineStatisticsWrapper = styled.div`
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 100;
`;

export const DeviceAlarmStatisticsWrapper = styled.div`
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    box-sizing: border-box;
    z-index: 100;
`;

export const DeviceListWrapper = styled.div`
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 100;
`;


export const DeivceOnlineDurationWrapper = styled.div`
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 100;
    position: relative;
`;

export const DeviceAnalyseWrapper = styled.div`
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 100;
`;

export const DeviceTrafficStatisticsWrapper = styled.div`
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 100;
`;
