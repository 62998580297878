

class AutoScrollUtils {

    public static addAutoScroll(dom: HTMLElement): number {
        let suspend = false;
        let calcScrollTop = 0;
        dom.addEventListener('mouseover', (e)=> {
            suspend = true;
        });
        dom.addEventListener('mouseout', (e)=> {
            suspend = false;
        });
        dom.addEventListener('wheel', (e)=> {
            let scrollTop=dom.scrollTop;
            scrollTop += e.deltaY;
            dom.scrollTop = scrollTop;
            calcScrollTop = scrollTop;
        });

        let autoScrollTask = window.setInterval(()=> {
            if (suspend) {
                return;
            }

            let childDoms = dom.children;
            let childElements = [];
            for(let i=0; i<childDoms.length; i++) {
                let childDom = childDoms[i];
                if (childDom.nodeType == 1) {
                    childElements.push(childDom);
                }
            }

            let scrollTop = calcScrollTop;
            let prevScrollTop = scrollTop;
            let nextScrollTop = scrollTop;
            for(let childElement of childElements) {
                let offsetTop = childElement.offsetTop;
                if (scrollTop < offsetTop) {
                    nextScrollTop = offsetTop;
                    break;
                }
            }

            let maxScrollTop=dom.scrollHeight-dom.clientHeight;
            if (prevScrollTop < maxScrollTop && nextScrollTop >= maxScrollTop) {
                nextScrollTop = maxScrollTop;
            } else if (nextScrollTop >= maxScrollTop) {
                nextScrollTop = 0;
            }
            calcScrollTop = nextScrollTop;

            let animationDuration = 500;
            let startTimestamp = null;
            let requestAnimationFrameCallback = (timestamp)=> {
                if (startTimestamp == null) {
                    startTimestamp = timestamp;
                }
                let elapsed = timestamp - startTimestamp;
                let t = elapsed / animationDuration;
                t = t > 1 ? 1 : t;
                let scrollTop = (1-t) * prevScrollTop + t * nextScrollTop;
                dom.scrollTop = scrollTop;
                
                if (elapsed < animationDuration) {
                    requestAnimationFrame(requestAnimationFrameCallback);
                } else {
                    dom.scrollTop = nextScrollTop;
                }
            }
            requestAnimationFrame(requestAnimationFrameCallback);
        }, 2000);

        return autoScrollTask;
    }

    public static removeAutoScroll(autoScrollTaskId: number): void {
        window.clearInterval(autoScrollTaskId);
    }
}


export default AutoScrollUtils;

