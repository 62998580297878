import styled from "styled-components";

export const DeviceNetworkTrafficWrapper = styled.div`
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    font-size: 12px;
    color: #FFFFFF;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    height: 100%;
`;

export const TrafficPoolUsageChartWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 150px;
    height: 100%;
    position: relative;
    margin: 0px 5px 0px 0px;
    overflow: hidden;
`;

export const EChartWrapper = styled.div`
    position: absolute;
    width: 250px;
    height: 100%;
    z-index: 99;
`;

export const EChartHighlighData = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

export const EChartTitle = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-style: normal;
    font-size: 14px;
    bottom: 20px;
`;

export const StatisticDataValue = styled.div`
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
`;

export const StatisticDataName = styled.div`
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
`;

export const DeviceTrafficStatisticsList = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    overflow-y: hidden;
`;

export const DeviceTrafficStatisticsItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background: linear-gradient(0deg, rgba(55, 72, 108, 0.5) 0%, rgba(55, 72, 108, 0.0) 50%, rgba(55, 72, 108, 0.5) 100%);
    }
`;

export const DeviceIndex = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 30px;
    padding: 5px 10px 5px 5px;
    margin-right: 10px;
    border-radius: 3px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.3);
`;

export const DeviceNameAndTrafficUsage = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & .ant-progress {
        font-size: 0px;
        line-height: 1;
    }

    & .ant-progress-outer {
        font-size: 0px;
        line-height: 1;
    }

    & .ant-progress-inner {
        background-color: rgba(255, 255, 255, 0.2);
    }

    & .ant-progress-text {
        height: 0px;
        color: rgb(100, 200, 255);
        font-size: 12px;
        font-style: normal;
        font-family: Rajdhani;
        overflow: visible;
        display: flex;
        align-items: center;
    }
`;

export const DeviceName = styled.div`
    flex: 0 0 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    font-style: normal;
    padding: 0px 0px 2px 2px; 
`;

export const TrafficUsageText = styled.div`
    display: inline-block;
    width: 90px;
    text-align: right;
`;
