import styled from "styled-components";
import CircleElementsBackground from "./assets/circle.png";
import TechnicalLineBackground from "./assets/technical-line.png";

export const DeviceOnlineStatisticsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
`;

export const Content = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    position: relative;
`;

export const EchartOuterWrapper = styled.div`
    width: 160px;
    height: 100%;
    position: relative;
    margin: 0px 15px 0px 0px;
`;

export const EChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 100;
`;

export const CircleElementsBackgroundImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${CircleElementsBackground});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 99;
    opacity: 0.2;
`;

export const EChartHighlighData = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const StatisticDataValue = styled.div`
    font-family: Rajdhani;
    font-weight: bold;
    font-style: normal;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
`;

export const StatisticDataName = styled.div`
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    color: #FFFFFF;
`;

export const StatisticsOverview = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 10px 15px 10px 40px;
`;

export const StatisticsItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 5px 10px;
    & .ant-progress-steps-item {
        background-color: rgba(255, 255, 255, 0.15);
    }
`;

export const StatisticsItemTextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
`;

export const StatisticsItemLabel = styled.div`
    font-style: normal;
    font-size: 12px;
    padding: 5px 0px;
    color: rgb(189, 226, 243);
`;

export const StatisticsItemValue = styled.div`
    font-family: DS-DIGIT;
    font-size: 28px;
    padding-right: 5px;
`;

export const TechnicalLine = styled.div`
    position: absolute;
    height: 100%;
    width: 220px;
    background: url(${TechnicalLineBackground});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
`;
