import styled from "styled-components";

export const DeviceMetricsWrapper = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    grid-gap: 20px 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 0px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;

export const ChartHeader = styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 42px;
`;

export const ChartName = styled.div`
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 2px;
    white-space: nowrap;
`;

export const ChartHeaderColorBlock = styled.div`
    width: 3px;
    height: 25px;
`;

export const ChartCurrentValue = styled.div`
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-left: 10px;
`;

export const ChartInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`;

export const ChartLatestMetricTime = styled.div`
    font-size: 11px;
    padding: 2px;
`;

export const ChartUnit = styled.div`
    font-size: 11px;
    padding: 2px;
`;

export const EChartsWrapper = styled.div`
    flex: 1 1 auto;
`;

